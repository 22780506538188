<template>
  <div>
    <p class="endorsement-title">{{ $t("report.endorsement_report_info_month") }}</p>
    <div class="graphic-container">
      <apexchart
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
        width="100%"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import { GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
export default {
  name: "PurchasingReportChart",
  props: [
    "startDate",
    "endDate",
    "currencyRateOptions",
    "instructionTypeId",
    "waybillTypeId",
    "supplierCompanyId",
    "onClickButton",
  ],
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    chartOptions() {
      let length = 500;
      if (this.tmpSeries.length <= 15) {
        length = 700;
      } else {
        length = length + (this.tmpSeries.length - 7) * 30;
      }
      let tmpChartOptions = {
        chart: {
          type: "bar",
          height: 350,
          width: length,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: "rounded",
            columnWidth: "50%",
            barHeight: "20%",
            barPadding: 2, // Sütunlar arasındaki boşluk (örneğin, 4 piksel)
            barWidth: "25%", // Sütunların genişliği yüzde olarak belirtilir (örneğin, %80 genişlik)
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.chartCategories,
          tickPlacement: "on",
          hideOverlappingLabels: true,
          trim: false,
          minHeight: 200,
          rotate: -90,
          style: {
            fontSize: "12px",
            display: "inline-block",
            overflow: "hidden",
            fontWeight: 500,
          },
          title: {
            text: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        yaxis: {
          title: {
            text: this.transactionText,
          },
          labels: {
            formatter: (val) => {
              return val.toLocaleString("tr-TR");
            },
          },
          formatter: (val) => {
            return val.toLocaleString("tr-TR");
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val.toLocaleString("tr-TR");
            },
          },
        },
      };
      return tmpChartOptions;
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartCategories: [],

      tmpSeries: [],
      series: [],
      transactionText: "",

      test: "Merhaba",
    };
  },

  methods: {
    getItems() {
      this.series = [];
      this.chartCategories = [];
      let filters = {
        start_date: this.startDate,
        end_date: this.endDate,
        instruction_type_id: this.instructionTypeId,
        waybill_type_id: this.waybillTypeId,
        supplier_company_id: this.supplierCompanyId,
      };
      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "end_date", moment(this.endDate).format(LARAVEL_DATE_FORMAT));

      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/purchasing-waybill-chart-data",
        })
        .then((result) => {
          let waybillTransactionNetAmount = [];
          let tmpData = result.data;
          let self = this;
          _.forEach(tmpData, function (chartData, key) {
            self.chartCategories.push(key);
            let sumTransactionNetAmount = 0;
            chartData.forEach((item) => {
              sumTransactionNetAmount += Number(item.net_amount);
              self.transactionText = "Toplam: " + item.unit.code;
            });
            waybillTransactionNetAmount.push(sumTransactionNetAmount.toFixed(4));
          });

          let tmpPackingSeries = {
            name: "Toplam:",
            data: waybillTransactionNetAmount, // Dizi olarak ata
          };
          this.series.push(tmpPackingSeries);
        });
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        this.$emit("onClickButton", false);
        this.getItems();
      }
    },
  },
};
</script>
<style scoped>
.endorsement-title {
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  margin-top: 5px;
  color: #5cb7e0;
}
.graphic-container {
  overflow-x: scroll;
  display: flex;
  position: relative;
}
</style>
